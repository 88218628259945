import styled from "styled-components";
import { appTheme } from "../../project/brand/project";
export const Table = styled.div`
  border-collapse: collapse;
  display: flex;
  flex-direction: column;
  row-gap: 0px;
  margin-top: 0;
  border-top: 0px solid #d9d9d9;
  width: 100%;
  width: -webkit-fill-available;
  padding: 0;
  gap: 24px;
  &.record {
    border-top: 0px solid #d9d9d9;
    padding: 0px 0px;
  }
  &.files {
    padding: 10px 4px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 20px;
  }
  &.gallery {
    margin-top: 16px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(152px, 1fr));
    gap: 20px;
  }
  &.norecord {
    padding: 5px 0px;
  }
  &.double {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: 0px solid #d9d9d9;
    column-gap: 20px;
    row-gap: 20px;
  }
  &.triple {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    border-bottom: 0px solid #d9d9d9;
    column-gap: 20px;
    row-gap: 20px;
    > div {
      display: flex;
      flex-direction: column;
      gap: 10px;
      position: relative;
      > div {
        background-color: transparent;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        > img {
          max-width: 60%;
          object-fit: contain;
          object-position: left;
        }
        > div:nth-child(1) {
          width: 100%;
          display: flex;
          flex-direction: column-reverse;
          > div:nth-child(2) {
            position: absolute;
            top: 10px;
            right: 10px;
            height: max-content;
            display: flex;
            flex: revert;
          }
        }
        > div:nth-child(2) {
          display: flex;
          flex-direction: column;
          gap: 10px;
          > div {
            display: flex;
            flex-direction: column;
            gap: 5px;
            > span::after {
              display: none;
            }
          }
        }
      }
    }
  }
  &.no-data {
    grid-template-columns: auto;
  }
  @media (max-width: 768px) {
    &.double {
      grid-template-columns: auto;
    }
    &.triple {
      grid-template-columns: auto;
    }
  }
  &.auto {
    width: auto;
  }
`;
export const ProfileImage = styled.div`
  min-width: 50px;
  max-width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 12px;
  background: #ececec;
  font-size: 10px;
  margin-right: 10px;
  transition: all 0.1s ease-in 0s;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  &.full {
    max-width: 48px;
    max-height: 48px;
    background-color: transparent;
    img {
      object-fit: contain;
      object-position: left;
    }
  }
  :hover {
    /* height: 62px; */
  }
`;
export const Th = styled.th`
  text-align: left;
  padding: 15px 8px 10px;
  background-color: #ddedeb;
  white-space: nowrap;
  color: #444;
  font-weight: bolder;
  border-radius: 0px;
  :first-child {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  :last-child {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  &.actions > div {
    display: flex;
    justify-content: right;
    gap: 10px;
    padding-right: 20px;
  }
`;

export const Tr = styled.div`
  border: 1px solid ${appTheme.stroke.soft};
  display: flex;
  margin: 0em 2px 0px;
  padding: 10px;
  background-color: ${appTheme.bg.white};
  border-radius: 8px;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px 2px; */
  cursor: pointer;
  && {
    /* .popup-child & {
      box-shadow: rgb(167 167 167 / 26%) 0px 0px 12px 0px;
    } */
  }
  && {
    .show-filter & {
      /* margin: 0em 2em 0 10px; */
    }
  }
  &:hover h4 {
    color: ${(props) => props.theme.themeForegound};
  }
  /* padding: 5px 26px; */
  padding-bottom: 12px;
  &:first-child {
    /* border-top: 1px solid ${(props) => props.theme.border};
    border-top-right-radius: 0px;
    border-top-left-radius: 0px; */
  }
  &:last-child {
    /* border-bottom: 1px solid ${(props) => props.theme.border};
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px; */
  }
  .double.table & {
    border-radius: 0px;
    &:first-child {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
    }
    &:nth-child(2) {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
    }
    &:nth-child(odd) {
      border-right: 0px solid ${(props) => props.theme.border};
      margin-right: 0;
    }
    &:nth-child(even) {
      margin-left: 0;
    }
    &:last-child {
      border-bottom: 0;
    }

    &:nth-last-child(2):nth-child(odd) {
      /* Apply styles to second-to-last child in last row (odd index) */
      border-bottom: 0;
      border-bottom-left-radius: 12px;

      /* Add any additional styles here */
    }
    &:nth-last-child(1):nth-child(even) {
      border-bottom-right-radius: 12px;
    }
    &:nth-last-child(2):nth-child(even) {
      border-bottom-right-radius: 12px;
    }
    &:nth-last-child(1):nth-child(odd) {
      border-bottom-left-radius: 12px;
    }
    &:last-child {
      border-bottom-right-radius: 12px;
    }
  }
  /* &:hover {
    transform: scale(1.005);
    transition: all 0.4s;
    border-radius:12px !important;
    z-index: 1000;
  } */
  &.bulk {
    flex-flow: wrap;
    gap: 10px;
  }
  @media screen and (max-width: 768px) {
    padding: 1em 1em 0.5em;
    margin: 0em 0em 0px;

    .double.table & {
      border-radius: 0;
      &:nth-last-child(2):nth-child(odd) {
        /* Apply styles to second-to-last child in last row (odd index) */
        border-bottom: 1px solid ${(props) => props.theme.border};
        border-bottom-left-radius: 0px;

        /* Add any additional styles here */
      }
      &:nth-child(2) {
        border-top-right-radius: 0px;
      }
      &:nth-last-child(1):nth-child(even) {
        border-bottom-right-radius: 0px;
      }
      &:nth-last-child(2):nth-child(even) {
        border-bottom-right-radius: 0px;
      }
      &:nth-last-child(1):nth-child(odd) {
        border-bottom-left-radius: 0px;
      }
      &:nth-child(odd) {
        border-right: 0px solid ${(props) => props.theme.border};
        margin: 0em 1em 0px !important;
      }
      &:nth-child(even) {
        margin: 0em 1em 0px !important;
      }
      &:first-child {
        border-top: 0px solid ${(props) => props.theme.border};
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
      }
      &:last-child {
        border-bottom: 0px solid ${(props) => props.theme.border};
        border-bottom-right-radius: 12px;
        border-bottom-left-radius: 12px;
      }
    }
  }
  /* box-shadow: rgb(167 167 167 / 26%) 0px 0px 12px 0px; */
  &:hover {
    /* background-color: #ddedeb; */
    box-shadow: 0px 4px 12px 0px rgba(${(props) => props.theme.theme}, 1);

    /* border: 1px solid ${(props) => props.theme.border}; */
  }
  &.single {
    padding: 0;
    margin: 0;
    margin: 0px 2px 30px;
    box-shadow: none; //rgba(0, 0, 0, 0.1) 0px 0px 8px 2px !important;
    margin-bottom: 20px;
    border: 0;
    @media (max-width: 768px) {
      margin: 5px 10px 9px 20px !important;
    }
    && {
      .popup-data & {
        margin: 0;
        margin-bottom: 20px;
      }
    }
  }
  &.single:hover {
    /* background-color: initial; */
    box-shadow: none;
    h4 {
      color: initial;
    }
  }
`;
export const Td = styled.div`
  text-align: left;
  padding: 5px 5px 0;
  position: relative;
  font-size: 14px;
  &.disabled {
    display: none;
  }
  &.custom {
    padding: 0px 5px 5px;
  }
  &.no,
  &.name {
    border: 1px solid gray;
  }
  &.has {
    border: 2px solid black;
    cursor: pointer;
  }
  &.no,
  &.has {
    text-align: center;
  }
  &.no svg {
    fill: grey;
  }
  &.name {
    text-overflow: "no-wrap";
  }
  &.actions {
    margin-left: auto;
    display: flex;
    gap: 5px;
    align-items: center;
  }
  &.actions > div {
    display: flex;
    justify-content: right;
    overflow-wrap: normal;
    margin-left: auto;
    margin-right: 5px;
    padding: 0;
    display: flex;
    justify-content: right;
    overflow-wrap: normal;
    margin-left: auto;
    margin-right: 0px;
    padding: 0px 0px 0px 5px;
    position: sticky;
    right: 0;
    bottom: 0;
    background: #ffffffe0;
  }
  &.right {
    text-align: right;
  }
  :last-child span:last-child::after {
    display: none;
  }
  &.bulk {
    min-width: 24%;
    max-width: 24%;
  }
`;

export const TrBody = styled.div`
  display: flex;
  flex-flow: wrap;
  &.small {
    font-size: 13px;
  }
  &.single {
    margin: 0px 0px 5px;
    padding: 10px 0;
    border-bottom: 1px solid lightgrey;
  }
  &.actions {
    display: flex;
    justify-content: right;
    margin: 5px;
  }
  @media (max-width: 768px) {
    &.nowrap {
      flex-flow: initial;
      margin-bottom: 5px;
    }
    &.actions {
      display: flex;
      justify-content: left;
      margin-top: 5px;
    }
  }
`;
export const Title = styled.span`
  margin-right: 5px;
  margin-bottom: 5px;
  color: #838894;
  font-size: 13px;
  &:after {
    content: " :";
  }
`;
export const Head = styled.h4`
  font-weight: bold;
  width: "100%";
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;

  &.single span,
  &.single {
    font-size: 18px;
    color: ${(props) => props.theme.theme};
    svg {
      color: ${(props) => props.theme.theme};
    }
  }
  span {
    font-size: 16px;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.015em;
    text-align: left;
  }
  cursor: pointer;
  svg {
    margin-right: 10px;
  }
`;
export const DataItem = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;

  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;
  }
  p {
    text-align: center;
    white-space: pre-line;
    display: contents;
  }
  &:after {
    content: " |";
    margin-left: 5px;
    color: #bcbcbc;
  }
  svg {
    margin-right: 5px;
  }
`;
export const Button = styled.button`
  color: ${(props) => props.theme.pageForeground};
  border: none;
  padding: 6px 12px;
  margin-right: 8px;
  cursor: pointer;
  font-size: 14px;
  white-space: nowrap;
  &.add {
    background-color: #4caf50;
  }
  &.menu {
    padding: 10px;
    margin-right: 0;
    text-align: left;
  }
  &.menu:last-child {
    margin-bottom: 0px;
  }
  &.edit {
    background-color: ${(props) => props.theme.pageBackground};
  }
  &.delete {
    background-color: red;
    color: white;
  }
  &:hover {
    transform: scale(1.1);
    transition: 0.2s ease-in-out;
  }
  svg {
    fill: white;
    margin-right: 5px;
    height: 0.9em;
  }
  @media (max-width: 768px) {
    svg {
      margin-left: 0px;
    }
  }
`;
export const Count = styled.div`
  padding: 1em 0em;
  @media screen and (max-width: 768px) {
    padding: 1em 1em 0.5em;
  }
  text-align: right;
  justify-content: flex-end;
  display: flex;
  text-wrap: nowrap;
  align-items: center;
  position: sticky;
  bottom: 0;
  background: white;
  z-index: 1001;
`;
export const ArrowButton = styled.button`
  border: 0;
  border: 1px solid #e2e4e9;
  outline: none;
  cursor: pointer;
  font-weight: bold;
  text-wrap: nowrap;
  position: relative;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  transition: all 1s ease 0s;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  padding: 0 15px;
  min-height: 40px;
  height: 40px;
  width: 50px;
  border-radius: 10px;
  margin: 4px 0px;
  background-color: ${(props) => props.theme.pageBackground};
  &:hover {
    color: ${(props) => props.theme.bgPrimary};
  }
`;
export const ArrowPagination = styled.button`
  border: 0;
  outline: none;
  cursor: pointer;
  font-weight: bold;
  text-wrap: nowrap;
  position: relative;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  &.button {
    border-left: 1px solid lightgray;
    border-radius: 0;
    padding: 0px 10px;
    background-color: #ffffff00;
  }
  &.button:last-child {
    margin-left: 10px;
  }
  svg {
    margin-left: 5px;
  }
  &:hover {
    color: ${(props) => props.theme.bgPrimary};
  }
`;
export const ButtonPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0em;
  z-index: 100;
  height: 50px;
  position: sticky;
  top: 0;
  background-color: white;
  svg {
    background-color: transparent;
  }
  && {
    .subList & {
      top: -25px;
    }
    .table & {
      top: -25px;
    }
    .files & {
      top: -25px;
    }
    .gallery & {
      top: -25px;
    }
  }
  @media (max-width: 768px) {
    padding: 0;
    /* margin-top: 60px; */
    && {
      .popup-data & {
        margin-top: 10px;
      }
    }
  }
`;
export const AddButton = styled.button`
  padding: 12px;
  border-radius: 8px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background: ${appTheme.primary.base};
  color: ${appTheme.text.white};
  outline: 0px;
  border: 0px solid rgb(221, 221, 221);
  margin: 0px;
  cursor: pointer;
  padding: 12px;
  margin-right: 0em;
  outline: none;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px 2px; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 5px;
  &.skip {
    border: 0px solid #8b8989;
    padding: 5px 20px;
    height: 35px;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: ${(props) => props.theme.pageBackground};
  }
  &.small {
    padding: 10px;
    gap: 8px;
    border-radius: 8px;
    display: flex;
  }
  &.skip svg {
    margin: 0px;
  }
  &.add {
  }
  && {
    /* .popup-child & {
      color: ${(props) => props.theme.secForeground};
      background: ${(props) => props.theme.secBackground};
    } */
  }
  &:hover {
    color: ${(props) => props.theme.themeForeground};
    background-color: ${(props) => props.theme.theme};
  }
  & > svg {
    /* margin-right: 10px; */
  }
  @media (max-width: 768px) {
    margin-right: 0px;
    justify-content: center;
    align-items: center;
    span {
      display: none;
    }
    & > svg {
      margin-right: 0px;
    }
  }
`;
export const FilterBox = styled.div`
  flex-direction: row;
  display: flex;
  gap: 10px;
  &.menu {
    width: 100%;
    margin-bottom: 5px;
    position: sticky;
    top: 0;
    padding-bottom: 5px;
    background: white;
    flex-wrap: wrap;
    column-gap: 5px;
    margin: -10px;
    padding: 3px 10px 0px;
    padding-bottom: 0;
    margin-bottom: 15px;
  }
  &.gap {
    gap: 10px;
  }
  .filter {
    margin-right: 0;
  }
`;
export const Filter = styled.button`
  background: transparent;
  font-size: initial;
  outline: none;
  border: 1px solid #e2e4e9;
  cursor: pointer;
  width: 32px;
  height: 32px;
  padding: 6px;
  gap: 6px;
  border-radius: 8px;
  background: ${(props) => props.theme.background};
  color: ${(props) => props.theme.foreground};
  cursor: pointer;
  transition: 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 15px;
    height: 15px;
  }
  &.single {
    padding: 0 0.5em;
    margin-top: 0;
    height: auto;
  }
  &.custom {
    margin: 0;
  }
  &.error {
    color: ${appTheme.state.error};
    border-color: transparent;
  }
  :hover {
    color: black;
  }
  &.desc svg {
    color: red;
  }
  &.asc svg {
    color: green;
  }
  &.left {
    margin: 0;
    margin-right: auto;
  }
  &.right {
    margin: 0;
    margin-left: auto;
  }
  &.center {
    margin: 0;
    margin-left: auto;
    margin-right: auto;
  }
  &.small {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 4px;
    svg {
      font-size: 15px;
    }
  }
  &.plain {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 4px;
    border: none;
    background: transparent;

    svg {
      font-size: 15px;
    }
  }
  &.inner-long {
    width: auto;
    text-wrap: nowrap;
    margin-left: 0;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    gap: 10px;
    height: auto;
    margin: 0;
    width: auto;
    padding: 7px 15px;
    font-size: 12px;
    margin-right: 0;
    margin-left: auto;
  }
  &:hover {
    color: ${(props) => props.theme.theme};
    svg {
      transition: 0.2s ease-in-out;
      transform: scale(1.1);
    }
  }
  &.error:hover {
    color: ${appTheme.state.error};
    border-color: transparent;
  }
  &.open {
    color: green;
  }
  &.imageedit {
    background-color: transparent;
    width: 21px;
    height: 21px;
    background: var(--state-information, #375dfb);
    color: white;
    border-radius: 50%;
    border: 3px solid;
    top: -10px;
    right: -10px;
    svg {
      font-size: 12px;
      z-index: 10;
      top: 0;
      margin-top: 3px;
      position: inherit;
      width: 9px;
      height: 9px;
    }
  }
  && {
    /* .popup-child & {
      color: ${(props) => props.theme.secForeground};
      background: ${(props) => props.theme.secBackground};
    } */
  }
`;
export const Filters = styled.div`
  /* display: flex;
  margin: 0;
  flex-flow: wrap;
  justify-content: center;
  align-items: baseline;
  gap: 10px; */
  display: none;
  /* margin-left: auto; */
  &.center {
    margin-left: auto;
  }
  && {
    .show-filter & {
      display: flex;
      border-right: 0px solid lightgrey;
      padding: 0px 10px 0px 0px;
      border-radius: 0px;
      width: 250px;
      top: 0px;
      gap: 10px;
      place-content: baseline left;
      flex-direction: column;
      align-self: baseline;
      position: sticky;
      top: 65px;
    }
  }
  @media (max-width: 768px) {
    flex-flow: wrap;
    max-width: 100%;
    margin-bottom: 10px;
    && {
      .show-filter & {
        position: absolute;
        background-color: lightgray;
        z-index: 1001;
        width: 200px;
        margin: 0px 1em;
        padding: 10px;
        border-radius: 10px;
        top: auto;
      }
    }
  }
`;
export const ToggleContainer = styled.label`
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
  margin-right: 10px;
`;

export const ToggleInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  span {
    box-shadow: 0 0 1px #2196f3;
  }
`;
export const NoData = styled.div`
  margin: 10px;
  width: -webkit-fill-available;
  /* border: 1px solid #ddedeb; */
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100px;
  flex-direction: column;
  border: 1px solid #e2e4e9;
  &.small {
    height: auto;
  }
  &.noshadow {
    box-shadow: none;
  }
  &.margin {
    margin: 30px 30px 0;
    border-radius: 10px;
  }
  &.white-list {
    margin: 0em 2px 0px;
    border-radius: 10px;
  }
  @media screen and (max-width: 768px) {
    &.white-list {
      margin: 0em 1em 0px;
      border-radius: 10px;
    }
  }
  &.white {
    border-radius: 10px;
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: inherit;
    gap: 10px;
    margin: 5px 0;
  }
  svg {
    margin: auto;
    font-size: 30px;
  }
  button {
    padding: 10px;
    font-size: 12px;
  }
  button svg {
    font-size: 15px;
  }
`;
export const Img = styled.img`
  height: 50px;
  object-fit: cover;
  width: 100px;
  &.contain {
    object-fit: contain;
  }
`;

export const ToggleSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e7f2f9;
  transition: 0.4s;
  box-shadow: 0 0 1px #2196f3;
  border-radius: 34px;
  &:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
    background-color: red;
  }

  ${ToggleInput}:checked + &::before {
    transform: translateX(18px);
    background-color: green;
  }
`;
export const More = styled.div`
  display: flex;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  right: 0;
  margin-top: 0px;
  margin-bottom: 0px;
  gap: 5px;
  &.callBack {
    margin-right: 0px;
    width: auto;
    border-radius: 10px;
    padding: 5px 10px;
    /* border: 1px solid rgb(221, 221, 221); */
    border: 1px solid #e2e4e9;
    font-size: 12px;
    height: max-content;
    line-height: 14px;
    svg {
      /* margin-right: 5px; */
      width: 15px;
    }
  }
  @media screen and (max-width: 768px) {
    /* span {
      display: none;
    } */
  }
  &.active,
  &:hover {
    color: white;
    background-color: ${(props) => props.theme.theme};
  }
`;
export const Actions = styled.div`
  display: flex;
  margin-left: auto;
  flex-direction: column;
  border-radius: 10px;
  div {
    margin: 0px 0px 5px 0;
    margin-right: 10px;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.5;
  }

  div:last-child {
    margin-right: 0px;
  }
  div.active {
    margin-right: 10px;
    text-decoration: none;
    cursor: pointer;
    opacity: 1;
  }
  @media screen and (max-width: 768px) {
    div {
      color: black;
    }
    div.active {
      color: #198ad6;
    }
  }
`;

export const ToolTipContainer = styled.div`
  display: flex;
`;
export const IconBox = styled.span`
  padding-top: 5px;
  &.display {
    padding-top: 0px;
    border: 1px solid;
    display: flex;
    height: 15px;
    width: 15px;
    font-size: 12px;
    justify-content: center;
    align-items: center;
  }
`;
export const TableContaner = styled.div`
  margin: 3px 3px 3px;
  padding: 0em 0em 0em;
  background-color: white;
  border-radius: 10px;
  width: -webkit-fill-available;
  background: white;
  && {
    .show-filter & {
      margin: 0em 2em 0 10px;
    }
  }
  @media screen and (max-width: 768px) {
    margin: 0em 1em 0px;
    height: calc(100vh - 180px);
  }
`;

export const TableView = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin: 0 auto;
  margin-bottom: auto;
  thead > tr {
    /* background-color: white; */
    border: 0px;
    background-color: ${appTheme.bg.weak};
    z-index: 100;
    gap: 0px;
    border-radius: 8px;
    opacity: 0px;
  }
  th {
    background-color: ${appTheme.bg.weak};
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.006em;
    text-align: left;
    padding: 8px 12px 8px 12px;
    border: 0px 1px 0px 0px;
  }
  &.small th {
    padding: 4px 10px;
    line-height: 14px;
  }
  tr:hover {
    color: ${(props) => props.theme.theme};
    /* border: 0; */
  }
  &.auto {
    width: auto;
  }
  &&.plain {
    tr {
      border: 1px solid;
    }
  }
`;
export const ThView = styled.th`
  text-align: left;
  padding: 20px 8px 15px;
  top: 0;
  z-index: 30;
  background: white;
  font-weight: bolder;
  border-radius: 0px;
  color: #626262;
  :first-child {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  :last-child {
    border-top-right-radius: 12px;
    border-right: 0px solid rgb(238 238 238);
  }
  div {
    display: flex;
    gap: 10px;
  }
  && {
    .plain & {
      background-color: transparent;
    }
  }
  &.true {
    position: sticky;
    left: 0px;
    z-index: 10;
  }
  &.bulk {
    max-width: 200px;
  }
  &.actions {
    display: flex;
    justify-content: right;
    padding-right: 20px;
  }
`;

export const TrView = styled.tr`
  border-bottom: 1px solid rgb(241 241 241);
  cursor: pointer;
  /* &:hover {
    background-color: #eaeaea;
    border-radius: 12px;
  } */

  &:last-child td:first-child {
    border-bottom-left-radius: 12px;
  }
  &:last-child td:last-child {
    border-bottom-right-radius: 12px;
  }
  &:last-child {
    border-bottom: 0;
  }
  &.bulk {
    max-width: 150px;
  }
`;
export const CoutSelector = styled.td`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10001;
  background: white;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 10px;
`;
export const TdView = styled.td`
  padding: 12px 10px 12px 10px;
  gap: 10px;
  opacity: 0px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.006em;
  text-align: left;
  color: ${appTheme.text.main};
  position: relative;
  &.true {
    position: sticky;
    left: 0px;
    background: white;
    z-index: 1;
  }
  &.no,
  &.name {
    border: 1px solid gray;
  }
  &.has {
    border: 2px solid black;
    cursor: pointer;
  }
  &.no,
  &.has {
    text-align: center;
  }
  &.no svg {
    fill: grey;
  }
  &.name {
    text-overflow: "no-wrap";
  }
  &.actions {
    justify-content: right;
    overflow-wrap: normal;
    position: sticky;
    right: 0px;
    background: white;
    border-left: 1px solid;
    box-shadow: 0px 2px 2px 0px #1b1c1d1f;
  }
  &.actions > div {
    display: flex;
    justify-content: right;
    overflow-wrap: normal;
    position: sticky;
    right: 0px;
    background: white;
    .buttons {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
  &.right {
    text-align: right;
  }
  &.bulk {
    padding: 4px 10px;
    line-height: 14px;
  }
`;
export const TrBodyView = styled.tbody``;
export const ListContainer = styled.div`
  display: flex;
  max-width: 100%;
  overflow: auto;
  /* display: flex;
  max-height: calc(100vh);
  min-height: calc(100vh - 330px); */
  /* && {
    .popup-child & {
      &.horizontal.medium {
        max-height: calc(45vh);
        min-height: calc(45vh);
      }
    }
  } */
  @media screen and (max-width: 768px) {
    min-height: auto;
  }
  /* overflow: auto; */
`;
export const ListContainerData = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;
`;

export const PageNumber = styled.button`
  padding: 10px 5px;
  background-color: white;
  font-weight: bold;
  border: none;
  font-size: 14px;
  margin-bottom: 0px;
  cursor: pointer;
  border-radius: 10px;
  min-width: 40px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px 2px;
  &.true {
    background-color: ${(props) => props.theme.theme};
    color: ${(props) => props.theme.themeForeground};
    font-weight: normal;
  }
`;
export const ListContainerBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ScrollContainerLayout = styled.div`
  padding: 1em;
  margin: 0em 2em 0px;
  width: calc(100% - 2em);
  background-color: white;
  border-radius: 10px;
  padding-bottom: 0em;
`;
export const ScrollLayout = styled.div`
  display: flex;
  flex: 1 1 100%;
  max-width: 100%;
  width: calc(100% - 0em);
  padding-bottom: 1em;
  flex-direction: column;
  min-height: calc(100vh - 14em);
  max-height: calc(100vh - 14em);
  .sub-page & {
    position: inherit;
    height: auto;
    flex-direction: column;
    min-height: 250px;
    max-height: calc(100vh - 12em);
  }
  .popup-child & {
    position: inherit;
    height: auto;
    flex-direction: column;
    min-height: 250px;
    max-height: calc(100vh - 20em);
  }
  .profile & {
    position: inherit;
    height: auto;
    flex-direction: column;
    min-height: 250px;
    max-height: calc(100vh - 20em);
  }
  && {
    .tab & {
      min-height: calc(100vh - 20em);
      max-height: calc(100vh - 20em);
    }
  }
  @media screen and (max-width: 768px) {
    min-height: calc(100% - 12em);
    max-height: calc(100% - 12em);
    min-height: inherit;
    position: fixed;
    top: 120px;
    left: 20px;
    right: 20px;
    width: auto;
    overflow: auto;
    && {
      .tab & {
        position: absolute;
        margin-top: 20px;
        height: calc(100% - 190px);
      }
      .sub-page & {
        position: inherit;
        height: auto;
        flex-direction: column;
        min-height: auto;
        max-height: calc(100vh - 12em);
        left: 0;
        top: 0;
        margin-top: 50px;
      }
    }
  }
`;
export const FileButton = styled.input`
  background: ${appTheme.bg.soft};
  padding: 4px 5px;
  border-radius: 10px;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  padding: 10px;
  outline: none;
  border: 0px solid #ddd;
  cursor: pointer;
  outline: none;
  border: 0px solid #ddd;
  cursor: pointer;
  &.red {
    background-color: red;
    color: white;
  }
  &.green {
    background-color: green;
    color: white;
  }
  &:hover {
    color: ${(props) => props.theme.bgPrimary};
    transform: scale(1.03);
  }
  & > svg {
    margin-right: 10px;
  }
  &.more > svg {
    margin-right: 0px;
  }
  &.more {
    position: relative;
  }
  &.more:hover div {
    display: flex !important;
    position: absolute;
    right: 0;
    top: 0px;
    z-index: 2005;
    white-space: nowrap;
    background-color: #f3f8fb;
    border-radius: 10px;
  }
  @media (max-width: 768px) {
    justify-content: center;
    white-space: nowrap;
    span {
      display: none;
    }
    svg {
      margin-right: 5px;
    }
  }
`;
