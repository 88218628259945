import React, { lazy, Suspense } from "react";
import Loader from "../../core/loader";

const Menu = lazy(() => import("../pages/menu"));
const Franchise = lazy(() => import("../pages/franchise"));

const Login = lazy(() => import("../../public/login/index"));
// const OldLogin = lazy(() => import("../../public/login/oldindex"));
const Page404 = lazy(() => import("../pages/page404"));
const Dashboard = lazy(() => import("../pages/dashboard"));
const Subscriber = lazy(() => import("../pages/package/subscriber"));
const Admin = lazy(() => import("../pages/franchise/admin"));
const Assignment = lazy(() => import("../pages/courseManagement/assignment"));
const Batch = lazy(() => import("../pages/courseManagement/batch"));
const Course = lazy(() => import("../pages/courseManagement/course"));
const CourseMaterial = lazy(() =>
  import("../pages/courseManagement/courseMaterial")
);
const Exam = lazy(() => import("../pages/courseManagement/exam"));
const Module = lazy(() => import("../pages/courseManagement/module"));
const Result = lazy(() => import("../pages/admin/examResult/index"));
const Submission = lazy(() => import("../pages/courseManagement/submission"));
const Student = lazy(() => import("../pages/studentManagement/student"));
const Faculty = lazy(() => import("../pages/studentManagement/faculty"));
const Institution = lazy(() =>
  import("../pages/studentManagement/Institution")
);
const Enrollment = lazy(() => import("../pages/studentManagement/enrollment"));
const Category = lazy(() => import("../pages/studentManagement/category"));
const AboutUs = lazy(() => import("../pages/studentManagement/aboutUs"));
// const StudentCourse = lazy(()=> import("../pages/courseManagement/studentCourse"));
// const Landing = lazy(()=> import("../pages/studentPanel/landingPage/Landing"));
// const StudentModule = lazy(()=> import("../pages/studentPanel/StudentModule/StudentModule"));
const StudentExam = lazy(() =>
  import("../pages/studentPanel/StudentExam/StudentExam")
);
// const Question = lazy(()=> import("../pages/studentPanel/components/ExamPage/Question"));
// const ExploreCourses = lazy(()=> import("../pages/studentPanel/components/ExploreCourses"));
const ExamResult = lazy(() =>
  import("../pages/courseManagement/courseExam/examResult")
);
const Certificate = lazy(() =>
  import("../pages/studentPanel/certificate/Certificate")
);
// const Materials = lazy(()=> import("../pages/studentPanel/MaterialList/Materials"));
const CourseList = lazy(() =>
  import("../pages/studentPanel/course/CourseList")
);
const Material = lazy(() =>
  import("../pages/studentPanel/ModuleMaterial/Material")
);
const Exams = lazy(() => import("../pages/studentPanel/exam/Exam"));
const ResultExam = lazy(() =>
  import("../pages/studentPanel/resultExam/ResultExam")
);

//new pages**************************************************************************

const StudentCertificate = lazy(() =>
  import("../pages/student/certificate/Certificate")
);
// const StudentDashboard = lazy(() =>
//   import("../pages/student/landingPage/Landing")
// );
const ExamStudent = lazy(() => import("../pages/student/exam/Exam"));

const ExamList = lazy(() => import("../pages/student/examList/examList"));
const PublicExam = lazy(() => import("../pages/admin/publicExam"));
const PublicQuestion = lazy(() => import("../pages/admin/publicQuestion"));
const ShowPaper = lazy(() => import("../pages/student/showPaper"));
const Elements = lazy(() => import("../pages/settings/elements"));

const Landing = lazy(() => import("../pages/landing/landing"));

const RenderPage = (page, key, user, privileges) => {
  const renderComponent = (Component) => (
    <Suspense fallback={<Loader>Loading...</Loader>}>
      <Component key={key} {...privileges} />
    </Suspense>
  );
  switch (page) {
    case "login":
      return renderComponent(Login);
    case "menu":
      return renderComponent(Menu);
    case "franchise":
      return renderComponent(Franchise);
    case "dashboard":
      return renderComponent(Dashboard);
    case "subscriber":
      return renderComponent(Subscriber);
    case "admin":
      return renderComponent(Admin);
    case "assignment":
      return renderComponent(Assignment);
    case "batch":
      return renderComponent(Batch);
    case "course":
      return renderComponent(Course);
    case "course-material":
      return renderComponent(CourseMaterial);
    case "exam":
      return renderComponent(Exam);
    case "module":
      return renderComponent(Module);
    case "result":
      return renderComponent(Result);
    case "submission":
      return renderComponent(Submission);
    case "institution":
      return renderComponent(Institution);
    case "enrollment":
      return renderComponent(Enrollment);
    case "category":
      return renderComponent(Category);
    case "student":
      return renderComponent(Student);
    case "faculty":
      return renderComponent(Faculty);
    case "about-us":
      return renderComponent(AboutUs);
    case "student-course":
      return renderComponent(Course);
    // return renderComponent(StudentCourse);
    case "student-panel":
      return renderComponent(Landing);

    case "student-module":
      return renderComponent(Material);
    case "student-exam":
      return renderComponent(StudentExam);
    case "question":
      return renderComponent(Exams);
    case "explore-courses":
      return renderComponent(CourseList);
    case "exam-results":
      return renderComponent(ExamResult);
    case "result-exam":
      return renderComponent(ResultExam);
    case "certificate":
      return renderComponent(Certificate);
    case "public-exam":
      return renderComponent(PublicExam);
    case "public-question":
      return renderComponent(PublicQuestion);

    // New Designs
    case "elements":
      return renderComponent(Elements);
    case "student-certificate":
      return renderComponent(StudentCertificate);
    case "student-dashboard":
      return renderComponent(ExamList);
    case "exam-student":
      return renderComponent(ExamStudent);
    case "examList-student":
      return renderComponent(ExamList);
    case "show-paper":
      return renderComponent(ShowPaper);

    default:
      return renderComponent(Page404);
  }
};

export default RenderPage;
