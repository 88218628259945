import React, { useState, useEffect, useCallback, useRef } from "react";
import { PopContainer } from "../../../styles/containers/styles";
import { SubPageHeader } from "../../input/heading";
import { DropArea, UploadText, Progess } from "./styles";
import { Button, IconButton } from "../../elements";
import { GetIcon } from "../../../../icons";
// import CustomLabel from "../../input/label";
import { Bar } from "../../input/styles";
import { bulkUploadData } from "../../../../backend/api";
import { v4 as uuidv4 } from "uuid";
const ImageGallery = ({ openData, viewMode = "", api, showTitle = false }) => {
  const [id, setId] = useState("");

  useEffect(() => {
    const uniqueId = uuidv4();
    setId(uniqueId);
  }, []);
  const [currentApi, setCurrentApi] = useState(api);

  useEffect(() => {
    console.log({ api }, "changed");
    setCurrentApi(api); // Update local state when api prop changes
  }, [api]);

  const [images, setImages] = useState([]);
  const [totalPhotos, setTotalPhotos] = useState(0);
  const [lastIndex, setLastIndex] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [overallStatus, setOverallStatus] = useState("idle");
  const [maxTotalSizeMB, setMaxTotalSizeMB] = useState({ uploadedMB: 0, totalMB: 0, uploadedCount: 0, totalCount: 0 });
  const fileInputRef = useRef(null); // Create a ref for the file input
  useEffect(() => {
    setMaxTotalSizeMB({ uploadedMB: 50, totalMB: 100, uploadedCount: 0, totalCount: 20 });
  }, []);

  const updateOverallStatus = useCallback(() => {
    const uploadedCount = images.filter((image) => image.status === "uploaded").length;
    const failedCount = images.filter((image) => image.status === "failed").length;
    const pendingCount = images.filter((image) => image.status === "pending" || image.status === "waiting").length;

    console.log("Uploaded:", uploadedCount, "Failed:", failedCount, "Pending:", pendingCount, "image count", images.length); // Debugging log

    if (uploadedCount === images.length && images.length > 0) {
      setOverallStatus("completed");
      console.log("completed");
    } else if (isFailed) {
      setOverallStatus("waiting");
    } else if (pendingCount > 0) {
      setOverallStatus("uploading");
    }
  }, [images, isFailed]);
  useEffect(() => {
    updateOverallStatus();
  }, [isFailed, updateOverallStatus]);
  const uploadImage = useCallback(
    async (data, index) => {
      const formData = new FormData();
      formData.append("file", data.file);
      formData.append("id", openData.data._id);

      const updateImageStatus = (status) => {
        setImages((prevImages) => {
          const updatedImages = [...prevImages];
          updatedImages[index] = { ...updatedImages[index], status };
          return updatedImages;
        });
      };

      try {
        console.log({ currentApi });
        const response = await bulkUploadData(formData, currentApi);
        if (response.status === 200) {
          updateImageStatus("uploaded");
          setLastIndex(index);
        } else {
          console.warn("Uplaod Stoped! on: ", index);
          return false;
        }
        updateOverallStatus(); // Update overall status after each upload
        return true;
      } catch (error) {
        console.error("Error:", error);
        updateImageStatus("failed");
        updateOverallStatus(); // Update overall status after each upload
        return false;
      }
    },
    [openData.data._id, updateOverallStatus, currentApi]
  );

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const uploadImagesSequentially = useCallback(async () => {
    setIsUploading(true);
    setIsFailed(false);
    for (let index = parseInt(lastIndex); index < images.length; index++) {
      if (images[index].status === "pending") {
        const response = await uploadImage(images[index], index);
        if (!response) {
          setIsFailed(true);
          updateOverallStatus();
          console.warn("Uplaod Breaked! on: ", lastIndex);
          return;
        }
        await delay(2000); // Optional delay; can be adjusted
      }
    }
    setIsUploading(false);
    updateOverallStatus();
  }, [images, uploadImage, updateOverallStatus, lastIndex]);

  useEffect(() => {
    if (images.length > 0 && !isUploading) {
      uploadImagesSequentially();
    }
  }, [images, isUploading, uploadImagesSequentially]);

  // const fetchWithProgress = (url, formData, onProgress) => {
  //   return new Promise((resolve, reject) => {
  //     const xhr = new XMLHttpRequest();
  //     xhr.open("POST", url, true);

  //     xhr.upload.onprogress = (event) => {
  //       if (event.lengthComputable) {
  //         const percentComplete = (event.loaded / event.total) * 100;
  //         onProgress(percentComplete);
  //       }
  //     };

  //     xhr.onload = () => {
  //       if (xhr.status === 200) {
  //         resolve(xhr.response);
  //       } else {
  //         reject(new Error("Upload failed"));
  //       }
  //     };

  //     xhr.onerror = () => {
  //       reject(new Error("Network error"));
  //     };

  //     xhr.send(formData);
  //   });
  // };

  const handleFiles = (files) => {
    const newImages = [];
    let newTotalSize = totalSize;
    const validTypes = ["image/jpeg", "image/png"];

    [...files].forEach((file) => {
      if (validTypes.includes(file.type)) {
        newImages.push({ file, status: "pending" });
        newTotalSize += file.size;
      } else {
        console.warn(`${file.name} is not an image. Only JPEG and PNG formats are accepted.`);
      }
    });

    if (newImages.length > 0) {
      setImages((prevImages) => [...prevImages, ...newImages]);
      setTotalPhotos((prevCount) => prevCount + newImages.length);
      setTotalSize(newTotalSize);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    handleFiles(e.dataTransfer.files);
  };

  const uploadedSize = images.reduce((total, image) => {
    return image.status === "uploaded" ? total + image.file.size : total;
  }, 0);

  const totalUploaded = images.reduce((total, image) => {
    return image.status === "uploaded" ? total + 1 : total;
  }, 0);

  const uploadedMB = uploadedSize / (1024 * 1024);
  const usedMB = totalSize / (1024 * 1024);

  return (
    <PopContainer className={"data-layout " + viewMode}>
      {showTitle && <SubPageHeader title={"Upload Photos"} line={false} description={"Upload your event snaps here"} />}
      {/* <PhotoLimit>
        <CustomLabel percetage={true} percetageValue={(maxTotalSizeMB.uploadedMB * maxTotalSizeMB.totalMB) / 100} label={"Data Storage"} leftContent={`${maxTotalSizeMB.uploadedCount} of ${maxTotalSizeMB.totalCount} Photos Uploaded (${maxTotalSizeMB.totalCount - maxTotalSizeMB.uploadedCount} Remaining)`} rightContent={`Total ${maxTotalSizeMB.uploadedMB}/${maxTotalSizeMB.totalMB} used`} />
      </PhotoLimit> */}
      {(overallStatus === "uploading" || overallStatus === "waiting" || overallStatus === "completed") && (
        <Progess viewMode={viewMode}>
          <div className="info">
            <div className="count">
              {totalUploaded} of {totalPhotos} Photos Uploaded
            </div>
            <div className="status">
              {uploadedMB.toFixed(2)} MB of {usedMB.toFixed(2)} MB
              {isFailed ? (
                <span className="red">
                  <GetIcon icon={"progress"}></GetIcon> Waiting for connection
                </span>
              ) : overallStatus === "uploading" ? (
                <span>
                  <GetIcon icon={"progress"} rotate={true}></GetIcon> Uploading
                </span>
              ) : (
                overallStatus === "completed" && (
                  <span className="green">
                    <GetIcon icon={"success"}></GetIcon> Completed
                  </span>
                )
              )}
            </div>
            {overallStatus !== "completed" && (
              <Bar>
                <div style={{ width: `${(totalUploaded / totalPhotos) * 100}%` }}></div>
              </Bar>
            )}
          </div>
          {isFailed && (
            <div className="buttons">
              <Button value="Retry" ClickEvent={() => uploadImagesSequentially()} type="error" icon="reload"></Button>
            </div>
          )}
          {overallStatus === "completed" && (
            <div className="buttons">
              <IconButton value="Retry" ClickEvent={() => uploadImagesSequentially()} icon="copy"></IconButton>
            </div>
          )}
        </Progess>
      )}
      {!isUploading && (
        <DropArea onDragOver={(e) => e.preventDefault()} onDrop={handleDrop} onClick={() => fileInputRef.current.click()}>
          <GetIcon icon={"upload"} />
          <UploadText>
            <div>Choose a file or drag & drop it here.</div>
            <div>JPEG & PNG formats, up to {maxTotalSizeMB.totalMB - maxTotalSizeMB.uploadedMB} MB.</div>
          </UploadText>
          <Button value="Browse Images" type="secondary" />
          <input ref={fileInputRef} type="file" id="file-input" name={id} multiple onChange={(e) => handleFiles(e.target.files)} style={{ display: "none" }} />
        </DropArea>
      )}
    </PopContainer>
  );
};

export default ImageGallery;
